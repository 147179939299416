import modelSettings from 'acces-impot-settings-report'

const DEFAULT_GENDER = modelSettings.genders.m

export function personalizeTranslation({ key, personType, personGender, options }) {
  let finalKey = key
  let msg = this.$t(key)
  const deepKeys = [personType, 'other', personGender || '-', DEFAULT_GENDER]

  for (const k of deepKeys) {
    if (typeof msg !== 'object') break
    if (k && msg.hasOwnProperty(k)) {
      msg = msg[k]
      finalKey += `.${k}`
    }
  }
  return this.$t(finalKey, options)
}

export function mergeLocaleMessages(i18n, messages) {
  for (const locale in messages) i18n.mergeLocaleMessage(locale, messages[locale])
}

export function getTranslationKeyForAdmin(key) {
  const isTranslationDefined = key => this.$t(key) !== key
  const [, parentKey, childKey] = key.match(/^(.+)\.([^.]+)$/) || []

  return isTranslationDefined(`${parentKey}.admin.${childKey}`)
    ? `${parentKey}.admin.${childKey}`
    : key
}
