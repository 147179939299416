import Vue from 'vue'

export const EVENTS = {
  accountConfirmed: 'account-confirmed',
  accountLead: 'account-lead',
  beforeViewEnter: 'before-view-enter',
  viewLeave: 'view-leave',
  pageView: 'page-view',
  purchase: 'purchase',
  header: {
    accountIconClick: 'account-icon-click',
  },
  adminReportListCacheUpdate: 'adminReportListCacheUpdate',
  adminReportCacheUpdate: 'adminReportCacheUpdate',
  reloadApp: 'reloadApp',
}

export const EventBus = new Vue()
