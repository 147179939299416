<template>
  <transition name="slide-down">
    <header v-show="hasBeenVisibleOnce" class="site-header">
      <div class="site-header__inner" :style="{ maxWidth: siteContainerMaxWidth }">
        <div class="site-header__column">
          <MobileMenu
            ref="mobileMenu"
            class="site-header__mobile-menu"
            :isVisible="isMobileMenuVisible"
          />
          <transition name="slide-right">
            <nuxt-link
              v-show="isBackButtonVisible"
              :to="backButtonPath"
              class="site-header__back-arrow-link"
            >
              <IconArrowLeft class="site-header__back-arrow" />
            </nuxt-link>
          </transition>
          <nuxt-link
            :to="localePath(ROUTE_NAMES.home)"
            :class="['site-header__logo', { 'site-header__logo--right': isBackButtonVisible }]"
          >
            <IconAccesImpot class="site-header__logo-svg" />
          </nuxt-link>
        </div>

        <div class="site-header__column site-header__column--main">
          <span v-for="(link, index) in links" :key="`link-${index}`">
            <transition name="slide-down">
              <span
                v-show="!isReviewingDocument && !isAdminSection"
                :key="`link-${index}`"
                class="site-header__link-wrap"
                :class="`site-header__link-wrap--${link.modifier || link.textKey}`"
              >
                <LinkUnderline
                  class="site-header__link"
                  :to="link.to"
                  :handleClick="link.handleClick"
                  >{{ $t(link.textKey) }}</LinkUnderline
                >
              </span>
            </transition>
          </span>
          <transition name="slide-down">
            <div v-show="isAdminSection" class="site-header__floating-links">
              <div v-show="isAdminSection" class="site-header__floating-links-inner">
                <span class="site-header__link-wrap site-header__link-wrap--full"><Search /></span>
                <span class="site-header__link-wrap">
                  <LinkUnderline class="site-header__link" @click="handleAdminQuestion">{{
                    isMqFull ? $t('admin.helpNeeded') : $t('admin.helpNeededShort')
                  }}</LinkUnderline>
                </span>
                <span v-if="!isSupervisorCompleted" class="site-header__link-wrap">
                  <Button
                    class="site-header__button"
                    :size="BUTTON_STYLES.size.small"
                    :color="BUTTON_STYLES.color.sand"
                    :isLoading="isAssigningReport"
                    :isDisabled="isAssigningReport"
                    @click="assignReport()"
                  >
                    {{ $t(isMqFull ? 'admin.newReport' : 'admin.newReportShort') }}
                  </Button>
                </span>
              </div>
            </div>
          </transition>
          <NuxtLinkExtra
            class="site-header__account-nav"
            :class="{ 'site-header__account-nav--logged-in': isUserLoggedIn }"
            :to="localePath(ROUTE_NAMES.account.index)"
            :handleClick="goToAccountSection"
          >
            <IconAccountNav class="site-header__account-svg" />
          </NuxtLinkExtra>
          <div
            :class="[
              'site-header__button-wrap',
              { 'site-header__button-wrap--visible': isReviewingDocument },
            ]"
          >
            <ApproveButton class="site-header__button" :size="BUTTON_STYLES.size.small" />
            <Button
              :class="[
                'site-header__button',
                'site-header__button--group',
                { 'site-header__button--bigger-font': !isMqLarger },
              ]"
              :size="BUTTON_STYLES.size.small"
              :color="BUTTON_STYLES.color.sand"
              @click="showOverlayApprovalQuestionForm()"
            >
              {{ $t(isMqLarger ? 'questionCta' : 'questionCtaShort') }}
            </Button>
          </div>
        </div>
      </div>
    </header>
  </transition>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { DOCUMENT_TO_APPROVE_URL_PARAM } from 'acces-impot-settings-report'
import { EventBus, EVENTS } from '@/services/event-bus'
import { ROUTE_NAMES, SEARCH_URL_PARAM } from '@/constants/route-names'
import { goToHomeSection } from '@/helpers/url'
import AdminService from '@/api/services/admin/admin'
import ReportService from '@/api/services/report'
import IconArrowLeft from '@/components/_icons/IconArrowLeft.svg'
import IconAccesImpot from '@/components/_icons/IconAccesImpot.svg'
import IconAccountNav from '@/components/_icons/IconAccountNav.svg'
import LinkUnderline from '@/components/Link/LinkUnderline.vue'
import NuxtLinkExtra from '@/components/Link/NuxtLinkExtra.vue'
import Button, { BUTTON_STYLES } from '@/components/Form/Button.vue'
import { isAdminRouteComputed } from '@/components/Admin/utils'
import { INFO_PANEL_THEMES } from '@/components/InfoPanel.vue'
import ApproveButton from './ApproveButton.vue'
import MobileMenu from './MobileMenu.vue'

export default {
  name: 'Header',
  components: {
    IconArrowLeft,
    IconAccesImpot,
    IconAccountNav,
    LinkUnderline,
    NuxtLinkExtra,
    Button,
    ApproveButton,
    MobileMenu,
    Search: () => import('@/components/Header/Search.vue'),
  },

  data() {
    return {
      BUTTON_STYLES,
      hasBeenVisibleOnce: false,
      isAssigningReport: false,
      ROUTE_NAMES,
      isSupervisorCompleted: false,
      isSupervisorCompletedTimeout: null,
    }
  },

  computed: {
    ...isAdminRouteComputed,

    ...mapState('page', ['isVisible', 'siteContainerMaxWidth']),
    ...mapState('admin', ['currentReport', 'lastVisitedReportsPage']),

    ...mapGetters({
      isMqLarger: 'viewport/isMqLarger',
      isMqFull: 'viewport/isMqFull',
      isUserLoggedIn: 'account/isUserLoggedIn',
      hasLoginFormVisible: 'account/hasLoginFormVisible',
      isSupervisor: 'account/isSupervisor',
    }),

    parentPageUrl() {
      return this.$route.path.replace(/\/[^/]+\/?$/, this.$config.hasTrailingSlash ? '/' : '')
    },

    isHeaderVisible() {
      return this.isVisible
    },

    isReviewingDocument() {
      return this.$route.meta.isReviewingDocument
    },

    isMobileMenuVisible() {
      return !this.isMqLarger && !this.isBackButtonVisible
    },

    isBackButtonVisible() {
      return this.isReviewingDocument || this.isAdminReportPage
    },

    backButtonPath() {
      if (!this.isAdminSection) return this.parentPageUrl

      let route = { name: ROUTE_NAMES.admin.report.index }

      if (this.lastVisitedReportsPage) {
        route = {
          ...this.lastVisitedReportsPage,
          query: { ...this.lastVisitedReportsPage.query },
        }
        delete route.query[SEARCH_URL_PARAM]
      }

      return this.localePath(route)
    },

    // Needs to be a computed property to update on locale change
    links() {
      return [
        {
          to: { path: this.localePath(ROUTE_NAMES.home), hash: '#steps' },
          handleClick: goToHomeSection,
          textKey: 'howItWorks',
        },
        {
          to: { path: this.localePath(ROUTE_NAMES.home), hash: '#prices' },
          handleClick: goToHomeSection,
          textKey: 'pricing',
        },
        {
          to: { path: this.localePath(ROUTE_NAMES.home), hash: '#testimonials' },
          handleClick: goToHomeSection,
          textKey: 'testimonials',
          modifier: 'testimonials',
        },
        {
          to: this.localePath(ROUTE_NAMES.faq.security),
          textKey: 'security',
        },
        {
          to: this.localePath(ROUTE_NAMES.faq.index),
          textKey: 'faq',
        },
        {
          to: this.localePath(ROUTE_NAMES.contact),
          textKey: 'contact',
        },
      ]
    },
  },

  watch: {
    isHeaderVisible: {
      immediate: true,
      handler() {
        if (this.isHeaderVisible) this.hasBeenVisibleOnce = true
      },
    },

    isSupervisor: {
      immediate: true,
      handler() {
        clearTimeout(this.isSupervisorCompletedTimeout)

        if (this.isSupervisor) this.isSupervisorCompleted = true
        else {
          this.isSupervisorCompletedTimeout = setTimeout(
            () => (this.isSupervisorCompleted = false),
            400
          )
        }
      },
    },
  },

  methods: {
    ...mapActions({
      showOverlayWriteMessageForm: 'overlay/showOverlayWriteMessageForm',
      addFlashSuccessMessage: 'flash/addSuccessMessage',
    }),

    goToAccountSection(to) {
      EventBus.$emit(EVENTS.header.accountIconClick)
      this.isMobileMenuVisible && this.$refs.mobileMenu
        ? this.$refs.mobileMenu.showAccountMenu()
        : this.$router.push(to)
    },

    async assignReport() {
      if (this.isAssigningReport) return

      this.isAssigningReport = true
      await AdminService.assignNextAvailableReport({ appOrVm: this, language: this.$i18n.locale })
      this.isAssigningReport = false
    },

    showOverlayApprovalQuestionForm() {
      this.showOverlayWriteMessageForm({
        title: this.$t('approvalQuestion.writeUsTitle'),

        handleSubmit: async message => {
          const postDocumentId = this.$route.query[DOCUMENT_TO_APPROVE_URL_PARAM]
          const { locale } = this.$i18n

          await ReportService.sendApprovalQuestion({
            message,
            locale,
            postDocumentId,
          })
          this.addFlashSuccessMessage({
            key: `approval-question-${postDocumentId}`,
            text: this.$t('approvalQuestion.successMessage'),
          })
        },
      })
    },

    handleAdminQuestion() {
      const reportId = this.currentReport?.id

      this.showOverlayWriteMessageForm({
        title: this.$t('admin.helpForm.title'),
        info: reportId
          ? this.$t('admin.helpForm.subtitle')
          : this.$t('admin.helpForm.warningNoReportId'),
        infoTheme: reportId ? INFO_PANEL_THEMES.info : INFO_PANEL_THEMES.warning,

        handleSubmit: async message => {
          await AdminService.sendHelpMessage({
            appOrVm: this,
            reportId: String(reportId),
            message,
            successMessage: this.$t('admin.helpForm.successMessage'),
          })
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/components/Form/field-settings';
@import './header-settings';

$logo-padding-right-small: 5px;

.site-header {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  background: $background-color;
  box-shadow: 0px -10px 15px 15px transparentize($color-black, 0.92);
  width: 100%;
  height: $site-header-height-small;
  user-select: none;
  z-index: $site-header-z-index;

  @include min-screen($mq-large) {
    height: $site-header-height;
  }

  &__inner {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding: 0 $header-inner-padding-right-x-tiny 0 $header-inner-padding-left-x-small;

    @include min-screen($mq-small) {
      padding: 0 $header-inner-padding-right-x-small 0 $header-inner-padding-left-x-small;
    }

    @include min-screen($mq-large) {
      padding: 0 $header-inner-padding-right-x-large 0 $header-inner-padding-left-x-large;
    }

    @include min-screen($mq-full) {
      padding: 0 $header-inner-padding-right-x-full 0 $header-inner-padding-left-x-full;
    }
  }

  &__column {
    display: flex;
    position: relative;
    align-items: center;
    height: $site-header-height-small;
    white-space: nowrap;

    @include min-screen($mq-large) {
      height: $site-header-height;
    }

    &--main {
      flex: 1;
      justify-content: flex-end;
    }
  }

  &__floating-links {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
  }

  &__floating-links-inner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: $account-svg-size-small + $account-nav-padding * 2;

    @include min-screen($mq-small) {
      padding-right: $account-svg-size + $account-nav-padding * 2 + $account-svg-margin-right;
    }
  }

  &__mobile-menu {
    @include min-screen($mq-small) {
      padding-right: $logo-padding-right-small;
    }
  }

  &__back-arrow-link {
    @include fake-padding-link-after($hamburger-padding-x, $hamburger-padding-y);
    position: absolute;
    z-index: 10;
    top: calc(50% - 1px);
    left: -5px;
    height: 26px;
    font-size: 0;
    color: $color-brand-navy;
    transform: translateY(-50%);

    &:hover {
      .site-header__back-arrow {
        transform: translateX(-2px);
      }
    }

    &:before {
      content: '';
      position: absolute;
      background-color: $color-brand-blue-grey-light;
      z-index: 1;
      top: 50%;
      left: 50%;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__back-arrow {
    position: relative;
    z-index: 10;
    vertical-align: top;
    height: 26px;
    transition: transform 0.1s;
  }

  &__logo {
    display: inline-block;
    margin: 0 0 0 $hamburger-padding-x;
    padding: $header-logo-padding $header-logo-padding $header-logo-padding #{$header-logo-padding -
      $hamburger-padding-x};
    color: $color-brand-navy;
    cursor: pointer;
    transform: translateX(-1px);
    transition: transform 0.5s $transition-easing;

    @include min-screen($mq-larger) {
      margin: 0;
      padding: $header-logo-padding;
    }

    &--right {
      padding-left: $header-logo-padding;
      transform: translateY(-100vh);

      @include min-screen(between($mq-small, $mq-medium)) {
        transform: translateX(#{$hamburger-padding-x - 1 + $logo-padding-right-small});
      }

      @include min-screen($mq-larger) {
        transform: translateX(
          #{$hamburger-padding-x - 1 + $logo-padding-right-small + $hamburger-width}
        );
      }
    }
  }

  &__logo-svg {
    width: 180px;
    vertical-align: top;

    @include min-screen($mq-large) {
      width: 220px;
    }
  }

  &__link-wrap {
    display: none;
    height: 68px;
    vertical-align: top;

    @include min-screen($mq-larger) {
      display: inline-flex;
      margin-right: 28px;

      &:first-child {
        margin-left: 28px;
      }
    }

    @include min-screen($mq-full) {
      margin-right: 35px;

      &:first-child {
        margin-left: 35px;
      }
    }

    @include min-max-screen($mq-larger, $mq-full) {
      &--testimonials {
        display: none;
      }
    }

    &--full {
      flex: 1;
    }
  }

  &__link {
    margin: auto;
    padding: 10px;
    color: $header-color-link-text;
    font-size: $font-size-smaller;
    font-weight: bold;
    letter-spacing: $letter-spacing-uppercase;
    text-transform: uppercase;
  }

  &__account-nav {
    display: inline-block;
    position: relative;
    padding: $account-nav-padding;
    transform: scale(0.92);

    @include min-screen($mq-larger) {
      margin-right: $account-svg-margin-right;
      transform: none;
    }

    &:before {
      content: '';
      position: absolute;
      top: 6px;
      right: 7px;
      background-color: $color-brand-green;
      width: 7px;
      height: 7px;
      opacity: 0;
      border-radius: 50%;
      transform: scale(0.8);
      transition:
        opacity 0.1s,
        transform 0.2s;
    }

    &--logged-in {
      &:before {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &__account-svg {
    width: $account-svg-size-small;
    height: $account-svg-size-small;
    vertical-align: top;

    @include min-screen($mq-small) {
      width: $account-svg-size;
      height: $account-svg-size;
    }

    * {
      fill: $header-color-text;
      transition: fill $account-nav-transition-time;

      .site-header__account-nav:hover & {
        fill: $header-color-text-hover;
      }
    }
  }

  &__button-wrap {
    display: flex;
    position: absolute;
    top: -200px;
    right: 0;
    height: 100%;
    opacity: 0;
    transform: translateX(20px);
    transition:
      opacity 0.4s ease-in-out 0.1s,
      transform 0.4s ease-in-out 0.1s;

    &--visible {
      top: 0;
      opacity: 1;
      transform: translateX(0);
    }
  }

  &__button {
    margin: auto;

    &--group {
      margin-left: $button-space-betweeen-hor;
    }

    &#{&}--bigger-font {
      padding-top: $button-small-padding-vert - 1;
      padding-bottom: $button-small-padding-vert - 1;
      font-size: $button-small-font-size + 2;
    }
  }
}

.slide-down-enter-active,
.slide-down-leave-active {
  opacity: 1;
  transform: translateY(0);
  @include transition((transform, opacity), $transition-time-page-fade-in ease-in-out);
}

.slide-down-enter,
.slide-down-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.slide-right-enter-active {
  @include transition((transform, opacity), 0.4s ease-in-out);
}

.slide-right-leave-active {
  @include transition((transform, opacity), 0.3s ease-in-out);
}

.slide-right-enter,
.slide-right-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.fade-link-enter-active,
.fade-link-leave-active {
  opacity: 1;
  transition: opacity $transition-time-page-fade-in;
}

.fade-link-enter,
.fade-link-leave-to {
  opacity: 0;
}
</style>

<i18n src="@/locales/site-header.json" />
