<template>
  <component
    :is="to ? 'nuxt-link' : tag"
    :class="ctaClasses"
    :to="to || undefined"
    :disabled="disabledAttribute"
    v-on="$listeners"
  >
    <slot name="left" /><slot />
  </component>
</template>

<script>
export const BUTTON_TAGS = {
  button: 'button',
  a: 'a',
}
export const BUTTON_STYLES = {
  size: {
    small: 'small',
    medium: 'medium',
    large: 'large',
  },
  color: {
    green: 'green',
    red: 'red',
    grey: 'grey',
    sand: 'sand',
    transparent: 'transparent',
  },
  shape: {
    spade: 'spade',
    square: 'square',
  },
}

export default {
  name: 'Button',

  props: {
    tag: {
      type: String,
      default: BUTTON_TAGS.button,
      validator(value) {
        return Object.values(BUTTON_TAGS).includes(value)
      },
    },

    to: {
      type: [String, Object],
      default: '',
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      validator(value) {
        return Object.values(BUTTON_STYLES.size).includes(value)
      },
    },

    color: {
      type: String,
      default: BUTTON_STYLES.color.green,
      validator(value) {
        return Object.values(BUTTON_STYLES.color).includes(value)
      },
    },

    shape: {
      type: String,
      default: BUTTON_STYLES.shape.square,
      validator(value) {
        return Object.values(BUTTON_STYLES.shape).includes(value)
      },
    },

    isKeepingColorWhenDisabled: {
      type: Boolean,
      default: false,
    },

    isHovered: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    sizeWithDefault() {
      return !this.size && this.shape === BUTTON_STYLES.shape.spade
        ? BUTTON_STYLES.size.medium
        : this.size || BUTTON_STYLES.size.large
    },

    ctaClasses() {
      return [
        'button',
        `button--${this.sizeWithDefault}`,
        `button--${this.shape}`,
        ...(this.isDisabled && !this.isKeepingColorWhenDisabled ? [] : [`button--${this.color}`]),
        {
          'button--loading': this.isLoading,
          'button--hover': this.isHovered,
          'button--disabled': this.isDisabled && !this.isKeepingColorWhenDisabled,
          'button--disabled-keep-color': this.isDisabled && this.isKeepingColorWhenDisabled,
        },
      ]
    },

    disabledAttribute() {
      return this.isDisabled || (this.tag === BUTTON_TAGS.button && this.isLoading)
        ? 'disabled'
        : false
    },
  },
}
</script>

<style lang="scss" scoped>
@import './field-settings';

@mixin cta-spinner-properties($size, $color: $color-white) {
  @include spinner($size, $color, 1px);
  display: inline;
  top: calc(50% - #{floor($size / 2) + 1});
  left: 50%;
  margin-left: floor($size / 2) * -1;
  transition-duration: 0s;
}

.button {
  position: relative;
  display: inline-block;
  padding: ceil($button-large-padding-vert) 30px floor($button-large-padding-vert);
  border: 1px solid transparent;
  line-height: $button-line-height;
  font-size: $button-font-size;
  font-weight: bold;
  letter-spacing: 0.1em;
  vertical-align: middle;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  user-select: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  &--square {
    border-radius: $border-radius;
  }

  &--spade {
    border-radius: 1px #{$button-medium-height / 2};

    &.button--large {
      border-radius: 1px #{$button-large-height / 2};
    }
  }

  &--loading {
    &:before,
    &:hover:before,
    &.button--hover:before,
    &:focus:before {
      @include cta-spinner-properties(28px);
    }
  }

  &--medium {
    padding: ceil($button-medium-padding-vert) 30px floor($button-medium-padding-vert);
  }

  &--small {
    padding: $button-small-padding-vert $button-small-padding-hor;
    font-size: $button-small-font-size;
    line-height: $button-small-line-height;

    &.button--loading {
      &:before {
        @include cta-spinner-properties(22px);
      }
    }
  }

  &--green {
    background: $button-green;
    border-color: $button-green;
    color: $color-white;

    &:not(.button--disabled-keep-color) {
      &:hover,
      &.button--hover,
      &:focus,
      &:active {
        background: $button-green-hover;
        border-color: $button-green-hover;
      }
    }

    &.button--loading {
      color: $button-green;

      &:not(.button--disabled-keep-color) {
        &:hover,
        &.button--hover,
        &:focus,
        &:active {
          color: $button-green-hover;
        }
      }
    }
  }

  &--red {
    background: $button-red;
    border-color: $button-red;
    color: $color-white;

    &:not(.button--disabled-keep-color) {
      &:hover,
      &.button--hover,
      &:focus,
      &:active {
        background: $button-red-hover;
        border-color: $button-red-hover;
      }
    }

    &.button--loading {
      color: $button-red;

      &:not(.button--disabled-keep-color) {
        &:hover,
        &.button--hover,
        &:focus,
        &:active {
          color: $button-red-hover;
        }
      }
    }
  }

  &--sand {
    background: $button-sand;
    border-color: $button-sand;
    color: $color-brand-navy;

    &:not(.button--disabled-keep-color) {
      &:hover,
      &.button--hover,
      &:focus,
      &:active {
        background: $button-sand-hover;
        border-color: $button-sand-hover;
      }
    }

    &.button--loading {
      color: $button-sand;

      &:before,
      &:hover:before,
      &.button--hover:before,
      &:focus:before {
        border-color: $color-brand-navy;
      }

      &:not(.button--disabled-keep-color) {
        &:hover,
        &.button--hover,
        &:focus,
        &:active {
          color: $button-sand-hover;
        }
      }
    }
  }

  &--disabled,
  &--grey {
    background: $color-grey-4;
    border-color: $color-grey-4;
    color: #fff;
  }

  &--disabled {
    cursor: default;

    &:hover,
    &.button--hover,
    &:focus,
    &:active {
      background: $color-grey-4;
      border-color: $color-grey-4;
    }

    &.button--loading {
      color: $color-grey-4;

      &:hover,
      &.button--hover,
      &:focus,
      &:active {
        color: $color-grey-4;
      }
    }
  }

  &--disabled-keep-color {
    cursor: default;
  }

  &--grey {
    &:not(.button--disabled-keep-color) {
      &:hover,
      &.button--hover,
      &:focus,
      &:active {
        background: $color-grey-3;
        border-color: $color-grey-3;
      }
    }

    &.button--loading {
      color: $color-grey-4;

      &:not(.button--disabled-keep-color) {
        &:hover,
        &.button--hover,
        &:focus,
        &:active {
          color: $color-grey-3;
        }
      }
    }
  }

  &--transparent {
    background: transparent;
    border-color: $color-light-grey-1;
    color: $color-grey-5;

    &:not(.button--disabled-keep-color) {
      &:hover,
      &.button--hover,
      &:focus,
      &:active {
        background-color: $button-transparent-hover-bg-color;
        border-color: $color-grey-3;
        color: $color-grey-3;
      }
    }

    &:not(.button--disabled-keep-color) {
      &.button--loading {
        &,
        &:hover,
        &.button--hover,
        &:focus,
        &:active {
          color: transparent;
        }

        &:before {
          border-color: $color-grey-5;
        }
      }
    }
  }
}
</style>
