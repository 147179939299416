<template>
  <FieldWrapper class="checkbox" :class="{ 'checkbox--enabled': !isComputedDisabled }">
    <input
      :id="id"
      ref="field"
      class="checkbox__field"
      :class="{ 'checkbox__field--red': hasRedBorder }"
      type="checkbox"
      :checked="model"
      :disabled="isComputedDisabled"
      @change="updateModel"
      v-on="on"
    />
    <div class="checkbox__container">
      <div class="checkbox__label-wrap">
        <label class="checkbox__label" :class="{ 'checkbox__label--red': hasRedBorder }" :for="id">
          <span class="checkbox__box">
            <IconCheck class="checkbox__check" :class="{ 'checkbox__check--visible': model }" />
          </span>
          <span class="checkbox__label-text"
            ><slot v-if="$slots.label" name="label" /><template v-else>{{ label }}</template></span
          >
        </label>
      </div>
      <slot />
    </div>
  </FieldWrapper>
</template>

<script>
import IconCheck from '@/components/_icons/IconCheck.svg'
import FieldWrapper from './FieldWrapper.vue'
import { props, propsComputed } from './field-settings'

export default {
  name: 'Checkbox',
  components: {
    FieldWrapper,
    IconCheck,
  },

  props: {
    ...props,

    model: {
      type: Boolean,
      required: true,
    },

    hasRedBorder: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...propsComputed,
  },

  methods: {
    updateModel(event) {
      if (this.isComputedDisabled) return

      if (this.on.change) this.$nextTick(() => this.on.change(this.$refs.field))
      this.$emit('update:model', event.target.checked)
    },
  },
}
</script>

<style lang="scss" scoped>
@import './field-settings';

$box-size: 18px;

.checkbox {
  position: relative;

  &__field {
    position: absolute;
    left: -100000px;

    &:focus {
      & + .checkbox__container .checkbox__label {
        border-color: $color-border-focus;
      }
    }

    &--red {
      &:focus {
        & + .checkbox__container .checkbox__label {
          border-color: $field-red-color-focus;
        }
      }
    }
  }

  &__container {
    display: flex;
    position: relative;
    justify-content: flex-end;
    border-color: darken($color-border, 3);

    .checkbox--enabled &:hover {
      border-color: $color-border-focus;
    }
  }

  &__label,
  &__box,
  &__check,
  &__label-text {
    .checkbox--enabled & {
      outline: 0;
      cursor: pointer;
    }
  }

  &__label-wrap {
    display: flex;
    position: relative;
    flex: 1;
    border-color: inherit;
  }

  &__label {
    display: flex;
    position: relative;
    margin-bottom: 0; // #bootstrap-override
    border-color: inherit;

    .checkbox--enabled &:hover {
      border-color: $color-border-focus;
    }

    &--red {
      border-color: $field-red-color;

      .checkbox--enabled &:hover {
        border-color: $field-red-color-focus;
      }
    }
  }

  &__box {
    position: relative;
    overflow: hidden;
    top: ceil(($font-size-normal * $line-height-normal - 18px) / 2) - 1;
    width: $box-size;
    min-width: $box-size;
    height: $box-size;
    min-height: $box-size;
    background: $color-white;
    border-width: 1px;
    border-style: solid;
    border-color: inherit;
    border-radius: $border-radius - 1;
    transition: border-color 250ms;
  }

  &__check {
    position: absolute;
    top: 4px;
    left: 3px;
    width: 11px;
    height: 8px;
    opacity: 0;
    stroke: $color-text;
    stroke-width: 4;
    transform: scale(0.4);
    transition:
      opacity 0.3s,
      transform 0.3s;

    &--visible {
      opacity: 1;
      transform: scale(1);
    }
  }

  &__label-text {
    flex: 1;
    padding-left: 10px;
    line-height: $line-height-normal;
    white-space: normal;
  }
}
</style>
